export const newUserAction =state=>{
    return{
        type:"STATE_USER_NOTIFICATIONS",
        payload:state,
    };
};
export const notificacionAction =state=>{
    return{
        type:"STATE_NOTIFICACIONES_TOTAL",
        payload:state,
    };
};

